<template>
   <div class="subdistributor-manage-add-wrapper wrapper-scroll-view">
       <div class="cover flex-box-column">
           <div class="hd">
                <van-nav-bar left-arrow title="新增分销商"  @click-left="back"></van-nav-bar>
           </div>
           <div class="bd"></div>
       </div>
   </div>
</template>

<script>
import backMixins from "@/mixins/back";
export default {

    mixins:[backMixins]

}
</script>

<style scoped lang="less">


</style>
